<template>
  <div class="content-enhanced">
    <div
      id="map"
      class="content-map"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

const BOOKMARK_QUERY_PARAMETER_NAME = 'settings'

export default {
  data () {
    return {
      message: 'Loading map...'
    }
  },
  mounted () {
    this.loadMap()
  },
  beforeRouteLeave (to, from, next) {
    if (window.cmiRadarApp) {
      window.cmiRadarApp.$destroy()
    }
    next()
  },
  methods: {
    ...mapActions([
      'tracker'
    ]),
    async loadMap () {
      const cmiRadarUrl = this.$store.getters.cmiRadarUrl
      const version = process.env.NODE_ENV === 'production' ? '.0a93e066' : ''
      const cmiRadarStyleUrl = `${cmiRadarUrl}/cmi-radar${version}.css`
      const cmiRadarScriptUrl = `${cmiRadarUrl}/cmi-radar${version}.js`

      console.log('Downloading CMI Radar')
      await Promise.all([
        this.loadAsset(cmiRadarStyleUrl, 'style'),
        this.loadAsset(cmiRadarScriptUrl, 'script')
      ])

      const options = {
        settings: {
          bookmark: this.$route.query[BOOKMARK_QUERY_PARAMETER_NAME] || null,
          quickset: this.convertPathToQuickset(this.$route.path, this.$route.query)
        },
        urls: {
          alerts: this.$store.getters.alertsUrl,
          api: this.$store.getters.apiUrl,
          forecast: this.$store.getters.forecastUrl,
          gis: this.$store.getters.gisUrl
        }
      }

      try {
      // eslint-disable-next-line no-undef
        window.cmiRadarApp = window.cmiRadar.createApp('#map', options, this.cmiRadarTracker)
      } catch (e) {
        this.message = 'Map is not available. Please contact support. [CODE 10001]'
        this.$log.error('Error creating CMI Radar app', e)
        return
      }
      // Use JSON to stringify the object so that watch doesn't trigger for simply observing an object
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.cmiRadarApp.$store.watch(
        (state, getters) => getters.encodedBookmark,
        (bookmark, previousBookmark) => {
          /*
          Ignore state changes until the initial view rendered to retain simple urls until an interaction requires a bookmark
          The cmi-radar returns null until it considers the initial view is rendered, by looking for previousBookmark
          it ignores the state change for initial view itself
          */
          if (previousBookmark !== null) {
            this.$router.replace({
              path: '/',
              query: {
                [BOOKMARK_QUERY_PARAMETER_NAME]: bookmark
              }
            })
          }
        }
      )
    },
    loadAsset (url, type = 'script') {
      return new Promise((resolve, reject) => {
        try {
          const tagName = type === 'style' ? 'link' : type
          const tag = document.createElement(tagName)
          const container = document.head || document.body

          if (type === 'style') {
            tag.type = 'text/css'
            tag.href = url
            tag.rel = 'stylesheet'
          } else {
            tag.type = 'text/javascript'
            tag.src = url
          }

          tag.addEventListener('load', () => {
            resolve({ loaded: true, error: false })
          })

          tag.addEventListener('error', () => {
            reject(new Error(`Failed to load ${tagName} with url ${url}`))
          })

          container.appendChild(tag)
        } catch (error) {
          reject(error)
        }
      })
    },
    convertPathToQuickset (path, query) {
      if (path && typeof path === 'string' && path.length > 0) {
        let pathParts = path.split('/')
        if (pathParts[1] === 'location' && query) {
          let settings = {}
          if (parseFloat(query.lat) && parseFloat(query.lon)) {
            settings.location = [parseFloat(query.lon), parseFloat(query.lat)]
            if (query?.focus === 'hourly') {
              settings.focus = 'hourly'
            }
            return settings
          }
        } else if (pathParts[1] === 'station') {
          if (pathParts[2]) {
            return {
              station: pathParts[2]
            }
          }
        } else if (pathParts[1] === 'region') {
          if (pathParts[2]) {
            return {
              region: pathParts[2],
              hazards: query.hazards
            }
          }
        }
      }
      return null
    },
    cmiRadarTracker (type, options) {
      this.tracker({
        type: type,
        options: options
      })
    }
  }
}
</script>

<style scoped>
.content-enhanced {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr;
  grid-auto-rows: auto;
  grid-template-columns: 100%;
}

.content-map {
  display: block;
  width: 100%;
  height: 100%;
  padding: 40px;
  background: #efefef;
}
</style>
